import React from 'react';
import { graphql } from 'gatsby';
import Layout3 from '../components/layout';

import Sdgs from '../containers/sdgs';


const HomePage = (props: any) => {
  const { data } = props;

  return (
    <Layout3>
      <Sdgs />
    </Layout3>
  );
};

export default HomePage;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        description
      }
    }
  }
`;
